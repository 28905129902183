/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import BaseChip from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const days = [...Array(31).keys()].map(e => e + 1);
const months = [...Array(12).keys()].map(e => e + 1);
const years = [...Array(100).keys()].map(e => new Date().getFullYear() - e);

function Seo({ description, lang, meta, title, birthday, onNumber, onDate }) {

    const [day, setDay] = React.useState(days[0]);
    const [month, setMonth] = React.useState(months[0]);
    const [year, setYear] = React.useState(years[0]);
    React.useEffect(() => {
        onDate && onDate({
            day,
            month,
            year
        })
    }, [day, month, year])


    const handleChange = (event) => {
      setAge(event.target.value);
    };
  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Day</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={day}
          onChange={(e) => setDay(e.target.value)}
          autoWidth
          label="Day"
        >
            {
                days.map(e => <MenuItem value={e}>{ e }</MenuItem>)
            }
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Month</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          autoWidth
          label="Month"
        >
            {
                months.map(e => <MenuItem value={e}>{ e }</MenuItem>)
            }
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Year</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          autoWidth
          label="Year"
        >
            {
                years.map(e => <MenuItem value={e}>{ e }</MenuItem>)
            }
        </Select>
      </FormControl>
    </div>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
