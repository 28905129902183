import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import BaseChip from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { checkNumber } from '../library/number'

export const NumberChip = ({ children, num, size, styleProps }) => {
    let props = {}
    if (size) {
        props = {
            width: size,
            height: size,
            ...styleProps
        }
    }
    return <BaseChip sx={{  bgcolor: checkNumber(num).color, ...props }}> {children} </BaseChip>
}